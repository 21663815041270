// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import 'bootstrap'
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("parsleyjs/dist/parsley.min.js")
require("select2")
import 'bootstrap-icons/font/bootstrap-icons.css'
import "chartkick/chart.js"
// import './static/js/appointment_booking_widget.js';
const flatpickr = require("flatpickr");

function doctorLocationFilter(){


  var $locationSelector = $('#doctor_location')
  $locationSelector.select2({
    placeholder: "Select a location",
    width: '100%',
    height: '100px'
  });

  $('#doctor_location').select2({
    placeholder: "Select a location",
    width: '100%',
    height: '100px'
  });

  $locationSelector.on("change.select2", function (e) {
    console.log("select2:select", e.currentTarget.value);

    // scheduleJson.appointment_types.filter(appointmentType => appointmentType.id === 34)

    const doctorsBySelectedLocation = AppointmentTypeSelected[0].locations.filter(location => location.id == e.currentTarget.value)[0]?.doctors || [],
    tobeShownDoctors = doctorsBySelectedLocation.map(doctor => doctor?.id)

    $("a[class*='doctor-']").fadeOut()
    tobeShownDoctors.map(doctor_id => $(".doctor-"+doctor_id).fadeIn())
   });

}

$(document).ready(function() {

  doctorLocationFilter();
  $(document).on('turbolinks:render',() => {doctorLocationFilter()});

  function tel() {
    $("input[type='tel']").each(function () {
      $(this).keydown(function (e) {
        var output,
          $this = $(this),
          input = $this.val();

        if (e.keyCode != 8) {
          input = input.replace(/[^0-9]/g, '');

          if (input.length == 1) {
            console.log('input 1')
            var area = input.substr(0, 3);
          } else {
            console.log('input >1')
            var area = input.substr(0, 3);
          }

          var pre = input.substr(3, 3);
          var tel = input.substr(6, 4);


          if (area.length < 3) {
            console.log(area)
            output = "(" + area;

          } else if (area.length == 3 && pre.length < 3) {
            console.log('else == 3 and  < 3')

            output = "(" + area + ")" + pre;
          } else if (area.length == 3 && pre.length == 3) {
             console.log('are == 3 and  pre == 3')
            output = "(" + area + ")" + pre + "-" + tel;
          }
          $this.val(output);
        }
      });
    });
  }
  tel();

  $('.review .primary-btn').on("click", function(e) {
    e.stopPropagation();
    e.preventDefault();
    var newDescription = $(".input-otr #description").val();
    var url = $(location).attr('pathname')
    var token = url.split('/').pop()

    $.ajax({
      url: '/review/' + token,
      type: 'PATCH',
      data: { review: { description: newDescription, token: token } },
      success: function(response) {
        console.log("Appointment status updated successfully.");
        $( ".row-form" ).slideUp();
        $(".writeReviewEditRowRight").fadeIn();
      },
      error: function(xhr, status, error) {
        console.error("Error updating appointment status:", error);
        alert(xhr.responseJSON.message)
      }
    })
  })
});
// function pageRequestJs(current_page){
//   alert(current_page)
// }


// var slider = document.querySelector('.items');
// let isDown = false;
// let startX;
// let scrollLeft;

// slider.addEventListener('mousedown', (e) => {
//   isDown = true;
//   slider.classList.add('active');
//   startX = e.pageX - slider.offsetLeft;
//   scrollLeft = slider.scrollLeft;
// });
// slider.addEventListener('mouseleave', () => {
//   isDown = false;
//   slider.classList.remove('active');
// });
// slider.addEventListener('mouseup', () => {
//   isDown = false;
//   slider.classList.remove('active');
// });
// slider.addEventListener('mousemove', (e) => {
//   if(!isDown) return;
//   e.preventDefault();
//   const x = e.pageX - slider.offsetLeft;
//   const walk = (x - startX) * 3; //scroll-fast
//   slider.scrollLeft = scrollLeft - walk;
//   console.log(walk);
// });
// import './select_date';
// document.addEventListener('DOMContentLoaded', () => {
//     let alertMessage = document.getElementById('alert');

//     alertMessage.classList.add('alert-message--visible');

//     setTimeout((() => {
//         alertMessage.classList.remove('alert-message--visible');
//     }), 10000);

//     alertMessage.addEventListener('click', () => {
//         alertMessage.classList.remove('alert-message--visible');
//     });
// });
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
